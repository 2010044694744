/* global Component, __lodash__ */
class subheaderSectionComponent extends Component {

    static name() {
        return "subheaderSectionComponent";
    }

    static componentName() {
        return "subheaderSectionComponent";
    }

    data() {
        return {
            selectedCategory: "",
            menu: "dropdown-menu",
            scrolled: false,
            isMobile: $(window).width() <= 991,
            currentCategory: ""
        };
    }


    mounted() {
        return function () {
            let self = this;
            this.closeSubMenu.bind(this);
            //window.Event.$on()'category-select', self.closeSubMenu();
            this.subscribeEvent('close-submenu', this.closeSubMenu);
            window.addEventListener('scroll', function () {
                self.scrolled = $(this).scrollTop() > 120;
            });
            this.subscribeEvent('closeSandwichMenu', this.closeSandwichMenu);
            if (this.$route.query.category)
                this.currentCategory = this.$route.query.category.split('_')[0];
        };
    }

    getComputed() {
        return {
            homeCategory: function () {
                let code = (window.location.pathname.split('/'))[1];
                let list = [];
                for (let cat of this.$store.getters.getHomeCategories) {
                    let catInfo = this.$store.state.cat_by_code.get(cat.fields.CatCode);
                    if (catInfo && (!catInfo.settingsCode || catInfo.settingsCode && catInfo.settingsCode.split(",").includes(code))) list.push(catInfo.code)
                }
                return this.$store.getters.getHomeCategories.filter((x) => list.includes(x.fields.CatCode))
            },
            subMenuCSS2: function () {
                return {
                    'category-preview': true,
                    'scrolled': this.scrolled,
                    'dropdown-menu': this.selectedCategory !== "",
                    "d-none": this.selectedCategory === "",
                };
            },
            subMenuCSS: function () {
                return {
                    'nav-item': true,
                    'dropdown': true,
                };
            }
        };
    }

    getMethods() {
        return {
            searchCat: this.searchCat,
            getCatInfo: this.getCatInfo,
            filterByCategory: this.filterByCategory,
            openSubMenu: this.openSubMenu,
            closeSubMenu: this.closeSubMenu,
            closeSandwichMenu: this.closeSandwichMenu,
            redirectTo: this.redirectTo,
        };
    }

    redirectTo(url) {
        window.location.hash = url;
    }

    searchCat(catName, catObject) {
        if (catObject) {
            if (catName == catObject.name)
                return catObject;
            if (catObject.children && catObject.children.length > 0) {
                for (let subCat of catObject.children) {
                    //console.log(catName, subCat, subCat.name, 'catName,subCat,subCat.name')
                    let isSubCat = this.searchCat(catName, subCat);
                    if (isSubCat) return isSubCat;
                }
            }
        }
        return null;
    }

    getCatInfo(catName) {
        let subcat = this.searchCat(catName, this.$store.state.cat_list[0]);
        let subCategories = [];
        if (subcat) subCategories = subcat.children;
        return __lodash__.orderBy(subCategories, ['sort','name']);
    }

    filterByCategory(catCode) {
        if (this.isMobile) {
            this.emitEvent('closeSandwichMenu');
        }
        if (catCode) {
            let cat = this.$store.getters.getCategoryDataByCode(catCode);
            if(cat) {
                const encodeCat = encodeURI(`${cat.code}__${cat.name}`);
                window.document.title = this.capitalize(cat.name);
                if (this.$route.fullPath !== `/category/${encodeCat}?category=${encodeCat}`) {
                    window.scrollTo(0, 0);
                    this.emitEvent("close-submenu");
                    this.$router.push(`/category/${encodeCat}?category=${encodeCat}`);
                }
            }
        }
    }

    openSubMenu(cat) {
        if (this.getCatInfo(cat.fields.CatName).length == 0) {
            let storeCategory = this.$store.getters.getCategoryDataByCode(cat.fields.CatCode);
            if (storeCategory) {
                window.scrollTo(0, 0);
                this.emitEvent("close-submenu");
                this.$router.push('/items?category=' + encodeURI(`${storeCategory.code}__${storeCategory.name}`));
            }
        } else {
            if (this.selectedCategory && cat.fields.Code == this.selectedCategory.fields.Code) {
                this.closeSubMenu();
            } else {
                this.selectedCategory = cat;
            }
        }
    }

    closeSubMenu() {
        this.scrolled = false;
        this.selectedCategory = "";
    }

    closeSandwichMenu() {
        if (this.isMobile) {
            $(this.$el).find('#navbarSupportedContent').collapse('hide');
        }
    }


    getTemplate() {
        return `<nav class="navbar-expand-lg category-nav" role="navigation">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mr-auto categories" v-click-outside="closeSubMenu">
                    <template v-for="cat in homeCategory">
                        <template v-if="getCatInfo(cat.fields.CatName).length>0">
                            <li :class="[{'show': (selectedCategory == cat),'multi-link':true },subMenuCSS]"  @click="openSubMenu(cat)">
                                <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;" class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                    {{cat.fields.CatName}}
                                </span>     
                                <span v-else class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                    {{capitalize(cat.fields.CatName)}}
                                </span>
                                <ul :id="'navbarDropdown'+cat.fields.CatCode" :class="[{show:(selectedCategory == cat)},subMenuCSS2]"  aria-labelledby="false" >
                                  <div class="row">
                                    <div class="col category-menu">
                                      <categoryMenuComponent v-for="(subcat,keycat) in getCatInfo(cat.fields.CatName)"
                                                     :key="keycat"
                                                     :category="subcat"
                                      ></categoryMenuComponent>
                                      <div class="category-image" v-if="cat.fields.CatPhotoUri">
                                        <div class="image" v-bind:style="{backgroundImage: 'url(' + cat.fields.CatPhotoUri + ')'}"></div>
                                      </div>
                                    </div>
                                  </div>
                                </ul>
                            </li>
                        </template>
                        <template v-else>
                            <li v-if="cat.fields.CatCode" :class="[{'show': (selectedCategory == cat), 'single-link':true },subMenuCSS]"  @click="filterByCategory(cat.fields.CatCode)" @mouseover="closeSubMenu()">
                                <span class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                    {{capitalize(cat.fields.CatName)}}
                                </span>
                            </li>
                            <li v-if="!cat.fields.CatCode && cat.fields.CatLink.split(':').length > 1" :class="[{'show': (selectedCategory == cat), 'single-link':true },subMenuCSS]" @mouseover="closeSubMenu()">
                                <a :href="cat.fields.CatLink" target="_blank" style="text-decoration: none;">
                                    <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;" class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                        {{cat.fields.CatName}}
                                    </span>
                                    <span v-else class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                        {{capitalize(cat.fields.CatName)}}
                                    </span>
                                </a>
                            </li>
                            <li v-else :class="[{'show': (selectedCategory == cat), 'single-link':true },subMenuCSS]"  @click="redirectTo(cat.fields.CatLink);" @mouseover="closeSubMenu()">
                                <span v-if="$store.state.setting.NoForceCategoryName" style="text-transform: none;" class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                    {{cat.fields.CatName}}
                                </span>
                                <span v-else class="nav-link dropdown-toggle" :class="{'active': currentCategory == cat.fields.CatCode}" :id="'navbarDropdown'+cat.fields.CatCode" :data-toggle="'#menu'+cat.fields.CatCode" aria-haspopup="true" aria-labelledby="false">
                                    {{capitalize(cat.fields.CatName)}}
                                </span>
                            </li>
                        </template>
                    </template>
                  </ul>
                </div>
              </nav>`;
    }
}

subheaderSectionComponent.registerComponent();

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

